<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
import echarts from 'echarts'

require('echarts/theme/macarons')
import {debounce} from '@/utils'

export default {
  name: "UserScale",
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null,
      queryForm: {
        startTime: '',
        endTime: ''
      },
    }
  },
  mounted() {
    this.chart = echarts.init(this.$el, 'macarons')
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize()
      }
    }, 100)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    refreshCart(queryForm) {
      this.queryForm = queryForm
      this.initChart()
    },
    initChart() {
      this.$http({
        url: this.$http.adornUrl("/admin/report/appDuration"),
        method: "POST",
        data: this.queryForm
      }).then(({data}) => {
        if (data && data.code === 0) {

          const appDurationPlatformReport = data.result.appDurationReport
          const day = []

          const avgAppUserDurationSession = []

          appDurationPlatformReport.forEach(function (item) {
            day.push(item.statDate)
            avgAppUserDurationSession.push(item.avgAppUserDurationSession)
          })



          this.chart.setOption({
            title: {
              text: '核心使用次数'
            },
            tooltip: {
              trigger: 'axis'
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            legend: {
              data: ['人均主动打开次数']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: day,
              boundaryGap: false
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [
              {
                name: '人均主动打开次数',
                type: 'line',
                data: avgAppUserDurationSession
              }
            ]
          })
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err);
      })
    }
  }
}
</script>
