<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
import echarts from 'echarts'

require('echarts/theme/macarons')
import {debounce} from '@/utils'

export default {
  name: "RetentionRate",
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null,
      queryForm: {
        startTime: '',
        endTime: ''
      },
    }
  },
  mounted() {
    this.chart = echarts.init(this.$el, 'macarons')
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize()
      }
    }, 100)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    refreshCart(queryForm) {
      this.queryForm = queryForm
      this.initChart()
    },
    initChart() {

      this.$http({
        url: this.$http.adornUrl("/admin/report/retainedRate"),
        method: "POST",
        data: this.queryForm
      }).then(({data}) => {
        if (data && data.code === 0) {

          const retentionReport = data.result
          const day = []
          const retentionRate1 = []
          const retentionRate3 = []
          const retentionRate5 = []
          const retentionRate7 = []
          const retentionRate14 = []
          const retentionRate30 = []
          const retentionRate60 = []
          const retentionRate90 = []

          retentionReport.forEach(function (item) {
            day.push(item.statDate)
            retentionRate1.push(item.retentionRate1)
            retentionRate3.push(item.retentionRate3)
            retentionRate5.push(item.retentionRate5)
            retentionRate7.push(item.retentionRate7)
            retentionRate14.push(item.retentionRate14)
            retentionRate30.push(item.retentionRate30)
            retentionRate60.push(item.retentionRate60)
            retentionRate90.push(item.retentionRate90)
          })
          this.chart.setOption({
            title: {
              text: '用户留存率'
            },
            tooltip: {
              trigger: 'axis'
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            legend: {
              width: 350,
              data: ['次留率', '3留率', '5留率', '7留率', '14留率', '30留率', '60留率', '90留率']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: day,
              boundaryGap: false
            }],
            yAxis: [{
              type: 'value',
              axisLabel: {
                show: true,
                interval: 'auto',
                formatter: '{value}%'
              }
            }],
            series: [
              {
                name: '次留率',
                type: 'line',
                data: retentionRate1
              },
              {
                name: '3留率',
                type: 'line',
                data: retentionRate3
              },
              {
                name: '5留',
                type: 'line',
                data: retentionRate5
              },
              {
                name: '7留率',
                type: 'line',
                data: retentionRate7
              },
              {
                name: '14留率',
                type: 'line',
                data: retentionRate14
              },
              {
                name: '30留率',
                type: 'line',
                data: retentionRate30
              },
              {
                name: '60留率',
                type: 'line',
                data: retentionRate60
              },
              {
                name: '90留率',
                type: 'line',
                data: retentionRate90
              }
            ]
          })
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err);
      })


    }
  }
}
</script>
