<template>
  <div>
    <el-form :inline="true" :model="queryForm">
      <el-form-item label="日期">
        <el-date-picker v-model="createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button @click="refreshAllReport()" type="primary">搜索</el-button>
      </el-form-item>
    </el-form>


    <div style="display: flex">
      <UserScale ref="userScale" width="50%" />
      <MAU ref="mau" width="50%" />
    </div>

    <div style="display: flex;margin-top: 120px">
      <Retention ref="retention" width="50%" />
      <RetentionRate ref="retentionRate" width="50%" />
    </div>

    <div style="display: flex;margin-top: 120px">
      <AppDuration ref="appDuration" width="50%" />
      <AppOpenCount ref="appOpenCount" width="50%" />
    </div>

    <div style="display: flex;margin-top: 120px">
      <ImPrivate ref="imPrivate" />
    </div>
    <div style="display: flex;margin-top: 120px">
      <Follow ref="follow" />
    </div>
  </div>
</template>

<script>

import UserScale from './userScale'
import MAU from './mau'
import Retention from './retention'
import RetentionRate from './retentionRate'
import ImPrivate from './imPrivate'
import Follow from './follow'
import AppDuration from './appDuration'
import AppOpenCount from './appOpenCount'
import { pickerOptions } from "@/views/common/commonOpt";

export default {
  data() {
    return {
      createTimeArr: [],
      queryForm: {
        startTime: '',
        endTime: ''
      },
      pickerOptions: pickerOptions,
    }
  },
  mounted() {
    this.createTimeArr = [this.timeDefaultStart + ' 00:00:00', this.timeDefaultEnd + ' 23:59:59']
    this.refreshAllReport();
  },
  created() {
  },
  computed: {
    timeDefaultStart() {
      var date = new Date();
      date.setDate(date.getDate() - 120);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate());
    },
    timeDefaultEnd() {
      var date = new Date();
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate());
    }

  },
  components: {
    UserScale,
    MAU,
    Retention,
    RetentionRate,
    ImPrivate,
    Follow,
    AppDuration,
    AppOpenCount
  },
  methods: {
    refreshAllReport() {
      this.queryForm.startTime = this.createTimeArr[0]
      this.queryForm.endTime = this.createTimeArr[1]
      this.getUserScaleReport();
      this.getFollowReport();
      this.getImPrivateReport();
      this.getMauReport();
      this.getRetentionReport();
      this.getRetentionRateReport();
      this.getAppDurationReport();
      this.getAppOpenCountReport();

    },
    getUserScaleReport() {
      this.$refs.userScale.refreshCart(this.queryForm)
    },
    getFollowReport() {
      this.$refs.follow.refreshCart(this.queryForm)
    },
    getImPrivateReport() {
      this.$refs.imPrivate.refreshCart(this.queryForm)
    },
    getMauReport() {
      this.$refs.mau.refreshCart(this.queryForm)
    },
    getRetentionReport() {
      this.$refs.retention.refreshCart(this.queryForm)
    },
    getRetentionRateReport() {
      this.$refs.retentionRate.refreshCart(this.queryForm)
    },
    getAppDurationReport() {
      this.$refs.appDuration.refreshCart(this.queryForm)
    },
    getAppOpenCountReport() {
      this.$refs.appOpenCount.refreshCart(this.queryForm)
    }
  }
}
</script>
